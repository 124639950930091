<template>
  <div>
    <h4 class="mapH5">
      <i class="park_h4_spack"></i>
      <span>日均泊位时长利用率</span>
      <el-tooltip class="item"
                  effect="dark"
                  content="日均泊位时长利用率，数据来自于最近30天，该泊位日均时长利用率的平均值"
                  placement="top">
        <i class="el-icon-question parking-icon park_h4_spack_icon"></i>
      </el-tooltip>
    </h4>
    <el-amap vid="amapDemo"
             :center="center"
             cursor="default"
             mapStyle="amap://styles/95a2766c67523ecb6ac7b9c1f80f239e"
             class="amap-demo"
             :zoom="zoom"
             :amap-manager="amapManager"
             :events="events"
             ref="map">
      <div class="fullFigure">
        <h1>日均泊位时长利用率</h1>
        <div><span class="fullFigure-three"></span> <span>40%以上</span></div>
        <div><span class="fullFigure-two"></span> <span>20%~40%</span></div>
        <div><span class="fullFigure-one"></span> <span>20%以下</span></div>
      </div>
      <!--      车场点-->
      <el-amap-marker vid="parkMarkers"
                      v-for="(marker) in parkMarkers"
                      :key="marker.parkId"
                      :position="marker.position"
                      :content="marker.template"
                      :events="marker.events"
                      :offset="[-3, -3]">
      </el-amap-marker>
      <!--     泊位点-->
      <el-amap-marker vid="berthMarkers"
                      v-for="(marker, index) in berthMarkers"
                      :key="index"
                      :position="marker.position"
                      :content="marker.content"
                      :events="marker.events"
                      :offset="[-3, -3]">
      </el-amap-marker>
      <!-- 提示信息 -->
      <el-amap-info-window :position="berthData.position"
                           :content="berthData.content"
                           :visible="berthData.visible"
                           :events="berthData.events"></el-amap-info-window>
    </el-amap>
  </div>
</template>
<script>
import { AMapManager } from "vue-amap";
let amapManager = new AMapManager();
export default {
  props: ['park_id', 'activePark'],
  data() {
    return {
      park_icon: require("./home_icon_sprites.png"),
      parkMarkers: [],
      berthData: {
        position: [0, 0],
        content: "",
        events: {},
        visible: false,
      },
      center: [116.395645, 39.929986],
      zoom: 8,
      amapManager: amapManager,
      events: {
      },
      berthMarkers: []
    }
  },
  watch: {
    park_id: {
      handler() {
        this.zoom = 14
        this.dayAverage()
        this.getMapParkName()
      }
    }
  },
  methods: {
    getMapParkName() {
      this.$axios.get('/acb/2.0/map/listNearbyParks', { data: { radius: 5000, parkId: this.park_id } }).then(res => {
        this.parkMarkers = []
        let arrMapData = []
        let that = this
        res.value.forEach(item => {
          if (item.longitude && item.latitude) {
            arrMapData.push(item)
          }
        });
        arrMapData.forEach((item, index) => {
          // '-320px', '-258px'
          if (index === 0) {
            that.center = [parseInt(item.longitude) / 1000000, parseInt(item.latitude) / 1000000]
            that.zoom = 14
          }
          let berthUtilization = item.berthUtilization.split('%')[0] * 1
          let icon = `background: url('${this.park_icon}') no-repeat -10px -382px;`;
          if (berthUtilization > 40) {
            icon = `background: url('${this.park_icon}') no-repeat -10px -258px;`; 
          } else if (berthUtilization >= 20 && berthUtilization <= 40) {
            icon = `background: url('${this.park_icon}') no-repeat -10px -322px;`;
          } else {
            icon = `background: url('${this.park_icon}') no-repeat -10px -382px;`;
          }
          if (this.park_id === item.parkId) {
            if (berthUtilization > 40) {
              icon = `background: url('${this.park_icon}') no-repeat -10px -258px;`;
            } else if (berthUtilization >= 20 && berthUtilization <= 40) {
              icon = `background: url('${this.park_icon}') no-repeat -10px -322px;`;
            } else {
              icon = `background: url('${this.park_icon}') no-repeat -10px -382px;`;
            }
          }
          this.parkMarkers.push({
            position: [parseInt(item.longitude) / 1000000, parseInt(item.latitude) / 1000000],
            template: `<div class="iconDefault" style="${icon}"></div>`,
            draggable: false,
            visible: true,
            events: {
              mouseover(o) {
                that.berthData = {
                  position: [parseInt(item.longitude) / 1000000, parseInt(item.latitude) / 1000000],
                  content: `<div>
                                  车场名称: ${item.parkName} <br>
                                  标签: ${item.tagDesc} <br>
                                  日均泊位时长利用率: ${item.berthUtilization} <br>
                                  日均泊位周转次数: ${item.berthTurnover}(次) <br>
                                  平均停车时长: ${item.avgParkTime}
                              </div>`,
                  events: {},
                  visible: true
                }
              },
              mouseout() {
                that.berthData.visible = false;
              }
            }
          })
        })
      })
    },
    dayAverage() {
      this.$axios.get('/acb/2.0/parkBerthStatic/berthUtilization', { data: { 'Session-Id': sessionStorage.token, parkId: this.park_id } }).then(res => {
        let arrMapData = []
        let that = this
        that.berthMarkers = [];
        res.value.forEach(item => {
          if (item.longitude && item.latitude) {
            arrMapData.push(item)
          }
        });
        arrMapData.forEach((item) => {
          let berthColor = '';
          let berthRate = item.berthUtilization.slice(0, item.berthUtilization.length - 1);
          if (berthRate >= 40) {
            berthColor = '#f4416d'
          } else if (berthRate >= 20 && berthRate < 40) {
            berthColor = '#f8e71c'
          } else {
            berthColor = '#42d1b8'
          }
          that.berthMarkers.push({
            position: [parseInt(item.longitude) / 1000000, parseInt(item.latitude) / 1000000],
            content: `<div style="width: 10px; height: 10px; background: ${berthColor}; border-radius: 50%;"></div>`,
            draggable: false,
            visible: true,
            events: {
              init(o) {
                // o.berthId = e.berthId;
              },
              click: () => {
                // alert('click');
              },
              mouseover(o) {
                // that.berthData.visible = true;
                that.berthData = {
                  position: [parseInt(item.longitude) / 1000000, parseInt(item.latitude) / 1000000],
                  content: `<div>
                                  泊位号: ${item.berthCode} <br>
                                  日均泊位时长利用率: ${item.berthUtilization}
                              </div>`,
                  // content: item.template,
                  events: {},
                  visible: true
                }
              },
              mouseout() {
                that.berthData.visible = false;
              }
            }
          })
        })
      })
    }
  },
  mounted() {
  }
}
</script>
<style scoped lang="stylus" rel="stylesheet/stylus">
.amap-demo {
  height: 400px;
  position: relative;
}

>>>.iconDefault {
  width: 40px;
  height: 42px;
  transform: translate(-9px, -12px) scale(0.5);
}

.mapH5 {
  margin-top: 20px;
  margin-bottom: 20px;

  >span {
    font-size: 16px;
    opacity: 0.65;
    vertical-align: bottom;
  }

  .parking-icon {
    font-size: 16px;
  }
}

.parking-icon {
  font-size: 20px;
  cursor: pointer;
}
</style>
<style scoped lang="stylus" rel="stylesheet/stylus">
.fullFigure {
  width: 120px;
  background: #0D203B;
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  padding: 12px;

  div {
    transform: scale(0.96);
    margin: 10px 0;
    font-size: 12px;

    span {
      font-size: 12px;
      color: #fff;
    }
  }

  h1 {
    color: #D4E5F4;
    font-size: 12px;
  }

  .fullFigure-one {
    display: inline-block;
    width: 8px;
    height: 8px;
    background: #42d1b8;
    margin-right: 5px;
  }

  .fullFigure-two {
    display: inline-block;
    width: 8px;
    height: 8px;
    background: #f8e71c;
    margin-right: 5px;
  }

  .fullFigure-three {
    display: inline-block;
    width: 8px;
    height: 8px;
    background-color: #f4416d;
    margin-right: 5px;
  }
}

.park_h4_spack_icon {
  color: #ccc;
}

.park_h4_spack {
  display: inline-block;
  width: 4px;
  height: 15px;
  background: linear-gradient(225deg, #4aa1fa, #2468f2);
  border-radius: 1px;
  vertical-align: bottom;
}
</style>
