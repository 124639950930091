<template>
  <div ref="current">
    <el-autocomplete
      class="inline-input"
      v-model="modelvalue"
      :fetch-suggestions="querySearchAsync"
      placeholder="请输入道路/车场名称"
      value-key="parkName"
      :trigger-on-focus="false"
      @select="handleSelect"
    ></el-autocomplete>
    <!-- <el-select v-model="modelvalue" style='width:100%'  @change="handleSelect" placeholder="请选择">
            <el-option
            v-for="item in parkList"
            :key="item.parkId"
            :label="item.parkName"
            :value="item.parkId">
            </el-option>
        </el-select> -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      className: 'my_auto_complete',
      modelvalue: '',
      restaurants: [],
      isScroll: true,
      page: 1,
      pageSize: 20,
    };
  },
  props: {
    slaveRelations: {
      default: '0,1,2',
    },
    parkType: {
      default: '',
    },
    areaIds: {
      default: '',
    },
    operationId: {
      default: '',
    },
    parkNameValue: {
      default: '',
    },
    dataSource: {
      default: '',
    },
    commercialStatus: {
      default: ''
    }
  },
  watch: {
    modelvalue(newVal) {
      if (!newVal) {
        this.$emit('valueChange', '');
      }
    },
  },
  methods: {
    setShowVal(val) {
      this.modelvalue = val;
    },
    setValue() {
      this.modelvalue = '';
      this.$emit('valueChange', '');
    },
    querySearchAsync(queryString, cb) {
      this.$emit('valueChange', '');
      queryString = queryString.replace(/\s/g, '');
      this.$axios
        .get('/acb/2.0/systems/loginUser/listParkName', {
          data: {
            page: 0,
            pageSize: 20,
            operationId: this.operationId,
            areaIds: this.areaIds,
            dataSource: this.dataSource,
            slaveRelations: this.slaveRelations,
            parkName: queryString,
            commercialStatus: this.commercialStatus,
            parkType: this.parkType
          },
        })
        .then(res => {
          if (res.value.list.length > 0) {
            this.$emit('valueChange', res.value.list[0]);
          } else {
            this.$emit('valueChange', { parkId: '', parkName: '' });
          }
          cb(res.value.list);
        });
    },
    handleSelect(item) {
      this.$emit('valueChange', item);
    },
  },
  mounted() {
    if (this.parkNameValue) {
      this.modelvalue = this.parkNameValue;
    }
  },
};
</script>
